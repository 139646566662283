@import "colors";

$fontFamilyLight: 'calibre-light', sans-serif;
$fontFamilyRegular: 'calibre-regular', sans-serif;
$fontFamilyMedium: 'calibre-medium', sans-serif;

////////////////////////////////////////////////////////////////////
/// GLOBAL
//////////
body {
  @extend .fontFamilyRegular;
  @extend .fontColorNormal;
  @extend .letterSpacingNormal;
  @extend .lineHeightBody;
  font-size: 16px;
  font-weight: normal;
}

.pointer {
  cursor: pointer;
}

////////////////////////////////////////////////////////////////////
/// TYPE CLASSES
//////////

// [ ALL FONTS ]
.display4,
.display3,
.display2,
.display1,
.headline,
.title,
.subheading,
.body2,
.body1,
.caption,
.small {
  @extend .fontMargins;
  font-weight: normal;
}

//[ LIGHT ]
.display4,
.display3 {
  @extend .fontFamilyLight;
}

//[ REGULAR ]
.display2,
.display1,
.headline,
.title,
.subheading,
.body1,
.caption {
  @extend .fontFamilyRegular;
}

//[ MEDIUM ]
.body2 {
  @extend .fontFamilyMedium;
}

.display4 {
  @extend .fontSizeDisplay4;
  @extend .lineHeightMatchSize;
  @extend .letterSpacingTightest;
}

.display3 {
  @extend .fontSizeDisplay3;
  @extend .lineHeightMatchSize;
  @extend .letterSpacingTighter;
}

.display2 {
  @extend .fontSizeDisplay2;
  @extend .lineHeightDisplay2;
  @extend .letterSpacingTight;
}

.display1 {
  @extend .fontSizeDisplay1;
  @extend .lineHeightDisplay1;
  @extend .letterSpacingTight;
}

.headline {
  @extend .fontSizeHeadline;
  @extend .lineHeightHeadline;
  @extend .letterSpacingTight;
}

.title {
  @extend .fontSizeTitle;
  @extend .lineHeightTitle;
  @extend .letterSpacingTight;
}

.subheading {
  @extend .fontSizeSubHeading;
  @extend .lineHeightSubheading;
  @extend .letterSpacingNormal;
}

.body2,
.body1 {
  @extend .fontSizeBody;
  @extend .fontFamilyRegular;
  @extend .lineHeightBody;
  @extend .letterSpacingNormal;
}

.caption {
  @extend .fontSizeCaption;
  @extend .lineHeightCaption;
  @extend .letterSpacingNormal;
}


////////////////////////////////////////////////////////////////////
/// FONT DESCRIPTORS
//////////

//[ MARGIN ]
.fontMargins {
  margin: 0;
}

//[ FONT FAMILY & WEIGHT ]
.fontFamilyLight {
  font-family: $fontFamilyLight;
}

.fontFamilyRegular {
  font-family: $fontFamilyRegular;
}

.fontFamilyMedium {
  font-family: $fontFamilyMedium;
}

//[ LETTER-SPACING ]
.letterSpacingNormal {
  letter-spacing: 0;
}

.letterSpacingTight {
  letter-spacing: -0.2px;
}

.letterSpacingTighter {
  letter-spacing: -1.2px;
}

.letterSpacingTightest {
  letter-spacing: -3.87px;
}

//[ LINE-HEIGHT ]
.lineHeightMatchSize {
  line-height: 1;
}

.lineHeightDisplay2 {
  line-height: 46px;
}

.lineHeightDisplay1 {
  line-height: 38px;
}

.lineHeightHeadline {
  line-height: 32px;
}

.lineHeightTitle {
  line-height: 28px;
}

.lineHeightSubheading {
  line-height: 24px;
}

.lineHeightBody {
  line-height: 22px;
}

.lineHeightCaption {
  line-height: 20px;
}

///[ COLORS ]
.fontColorNormal {
  color: $text;
}

.fontColorSecondary {
  color: $textSecondary;
}

.fontColorDisabled {
  color: $textDisabled;
}

.fontColorActive {
  color: $active;
}

.fontColorAccent1 {
  color: $accent;
}

.fontColorAccent2 {
  color: $warn;
}

.fontColorAccent3 {
  color: $alert;
}

.fontColorError {
  color: $alert;
}

.fontColorWhite {
  color: $white;
}

.fontColorWhiteMid {
  color: $whiteMedium;
}

/////////////////////
// SIZES

// Display4
.fontSizeDisplay4 {
  font-size: 56px;
}

// Display3
.fontSizeDisplay3 {
  font-size: 46px;
}

// Display2
.fontSizeDisplay2 {
  font-size: 38px;
}

// Display1
.fontSizeDisplay1 {
  font-size: 30px;
}

// Headline
.fontSizeHeadline {
  font-size: 24px;
}

// Title
.fontSizeTitle {
  font-size: 20px;
}

// Subheading
.fontSizeSubHeading {
  font-size: 16px;
}

// Body
.fontSizeBody {
  font-size: 14px;
}

// Caption
.fontSizeCaption {
  font-size: 12px;
}

// Caption
.fontSizeSmall {
  font-size: 10px;
}


:export {
  _fontFamilyLight: $fontFamilyLight;
  _fontFamilyRegular: $fontFamilyRegular;
  _fontFamilyMedium: $fontFamilyMedium;
}