@media only screen and (max-width: 1081px) {
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    padding: 10px;
  }
}

.ant-table-tbody > .ant-table-expanded-row:hover > td {
  background: inherit;
}

// This is a hack to fix the wrapping " · status "
.ant-table-tbody > tr > td.fixedWidth110 {
  width: 110px;
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  word-break: normal;
}

.lr-table-col-scale-to-content {
  white-space: nowrap;
  width: 1%;
}

.lr-table-no-hover {
  td:hover {
    background-color: inherit;
  }
}

.lr-table-row-clickable {
  td {
    cursor: pointer;
  }
}

.lr-stats_table {
  margin: 0 auto 64px;
  width: 80%;
  @media screen and (min-width: 1200px) {
    max-width: 960px;
  }

  tr:hover > td {
    background-color: transparent;
  }
  td {
    font-size: 130%;
    &:extend(.lr-color-percent-45);
  }

  & .lr-stats_table__td {
    text-align: right;
    &:extend(.lr-color-percent-85);
  }
}

.lr-table-load-more-footer {
  padding: 24px;
  text-align: center;
  width: 100%;
}

.lr-highlight-row {
  animation: HIGHLIGHT_TABLE_ROW 1.5s;
}

@keyframes HIGHLIGHT_TABLE_ROW {
  0% {
    background-color: fade(@success-color, 60%);
  }
  100% {
    background-color: inherit
  }
}