@import "_theme";
@import "_colors";
@import "_layout";
@import "_fonts";
@import "_tables";
@import "_forms";
@import "_type";
@import "_modal";
@import "_spacing";

.lr-interactive {
  &:hover {
    cursor: pointer;
  }

  &.hover-background {
    background-color: inherit;

    &:hover {
      background-color: @primary-1;
    }

    transition: background-color 0.3s;
  }
}

// Logo
.lr-logo-wrapper {
  display: inline-block;
}

.lr-logo-link {
  display: inline-block;
}

/// MENU
@lr-menu-width: 120px;
.lr-menu {
  width: @lr-menu-width;
}

/// AVATARS
.lr-avatar-xl {
  width: 64px;
  height: 64px;
  line-height: 64px;
  font-size: 32px;

  float: left;
  margin-right: 1rem;

  &.ant-avatar.ant-avatar-icon {
    font-size: 32px;
  }
}

.lr-avatar-xl > * {
  line-height: 64px;
}

.qrCode {
  display: block;
  margin: 0 auto;
}

.backupCodes {
  margin: 0;
  padding: 0;

  &.codes {
    margin: 0;
    font-family: monospace;
    display: inline-block;
    padding: 4px 0;
    width: 50%;
    text-align: center;
  }
}

// CODE DISPLAY
.codeDisplay {
  background-color: rgba(0, 0, 0, 0.04);
  font-size: 12px;
  font-family: monospace;
  border-radius: 4px;
  padding: 16px;
  margin: 16px 0;
  word-wrap: break-word;

  &.fullCode {
    font-size: 24px;
    display: inline-block;
    margin: 16px auto;
    max-width: 100%
  }

  &.input {
    background-color: rgba(0, 0, 0, 0);
    margin-top: 0;
  }

  &.setValue {
    margin-top: 0;
    padding: 0 0.5rem;
  }
}

.cancel {
  color: @redCanceled;
}

.lr-smallerAntBtnPadding {
  padding: 0 7px;
}

pre.json {
  padding: 12px;
  margin: 8px 0;
  background-color: white;
  border: 1px solid @input-border-color;
  border-radius: @border-radius-base;
  font-size: 0.8em;
  white-space: pre-wrap;
  word-wrap: break-word;

  &.closed {
    height: 0;
    padding: 0;
    border: none;
  }
}

div.json {
  &:extend(pre.json);
  font-size: inherit;
  padding: 0;
  overflow: hidden;

  &.closed {
    height: 0;
    padding: 0;
    margin: 0;
    border: none;
  }

  & pre {
    &:extend(pre.json);

    background: none;
    border: none;
    border-bottom: 1px solid @input-border-color;
    border-radius: 0;
    margin: 0;
    white-space: pre-wrap;
  }

  & .footer {
    padding: 12px;

    & .ant-btn {
      margin: 8px;
    }
  }
}

// DetailValues
.lr-detail-value-row {
  margin-bottom: 14px;

  table {
    td {
      vertical-align: top;
      margin-bottom: 4px;
      color: rgba(0, 0, 0, 0.54);

      &:first-of-type {
        color: rgba(0, 0, 0, 0.8);
        min-width: 180px;
      }
    }
  }
}

.lr-interactive-list-item {
  padding: 4px 8px;
}

//
.lr-undolink {
  margin-left: 0.5em;
}

.lr-program-drawer {
  > .ant-form {
    > .ant-row {
      padding-right: 20px;
    }
  }
}

// Tag
.lr-tag {
  i {
    margin-right: 2px;
  }
}

//
.showMonoFont {
  font-family: monospace;
  font-size: 14px;
  line-height: 1;
}

@account-select-height: 64px;
#lr-account-select {
  display: flex;
  height: @account-select-height;
  margin-bottom: 24px;

  div {
    height: @account-select-height;
    display: flex;
    align-items: center;
    background-color: #edf3f7;

    overflow-wrap: normal;
    font-weight: 200;
    width: 100%;
  }
}

.lr-account-select:first-child {
  margin-top: 0;
}

.lr-account-select-option {
  padding: 12px 4px 12px 14px;
}

.ant-menu-item-disabled > .lr-account-menu-item {
  cursor: default;
}

/* Test Banner */
@lr-test-banner-top-offset: 4px;
@lr-test-banner-width: 220px;

#lr-test-banner-thin {
  position: absolute;
  top: 0;
  left: 0;
  height: @lr-test-banner-top-offset;
  width: 100vw;
  background-color: @warning-color;
  z-index: 1000;
}

#lr-test-banner-container {
  position: absolute;
  top: @lr-test-banner-top-offset;
  left: calc(50vw + @lr-menu-width - @lr-test-banner-width / 2);
  display: flex;
  justify-content: center;

  height: 30px;
  width: @lr-test-banner-width;
  z-index: 1000;
}

#lr-test-banner-thick {
  width: @lr-test-banner-width;
  padding-bottom: @lr-test-banner-top-offset;
  padding-top: 2px;

  display: flex;
  justify-content: center;
  align-items: center;

  color: white;
  background-color: @warning-color;
  font-size: 0.9em;
  font-weight: 900;
  letter-spacing: .15rem;

  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

#lr-test-banner-information {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 1em;
  height: 1em;
  margin-left: 8px;
  padding-left: 2px;
  position: relative;

  font-size: 0.85em;
  font-weight: 400;

  background-color: white;
  color: @warning-color;
  border-radius: 100%;
}

.lr-error-message {
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
}

.lr-align-right {
  display: flex;
  justify-content: flex-end;
}

.lr-space-between {
  display: flex;
  justify-content: space-between;
}

#lr-toggle-testing-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: @text-color;
}

.lr-dropdown {
  height: 30px;
}

.lr-auth-header {
  margin-bottom: 48px;
}

.lr-report-form-item {
  max-width: 375px;
  width: 100%;
}

.lr-report-form-radio {
  white-space: normal;
  margin-bottom: 0;
}

.lr-emoji-picker {
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    padding-top: 2px;
  }
}

#lr-login-form {
  line-height: normal;

  div.ant-form-item {
    margin-bottom: 0;
  }
}

#lr-program-filter-select {
  display: flex;
  justify-content: flex-start;
}

.lr-emoji-input-item {
  width: 100%;
}