//  Giftbit Color Settings
//  ------------------------------
//
//  Table of Contents:
//
//   1. Set up
//   2. Global Override
//     2a. Export

// 1. Set up
// ---------

$black: #000000;
$white: #FFFDFC;
$green: #36C97D;
$blue: #2056F7;
$red: #EB8483;

// Button Colors
$primary: $green;
$secondary: #F5F6F7;
$secondaryLabel: #0070FF;

// Text Type Colour
$text: rgba($black, .78);
$textSecondary: rgba($black, .54);
$textDisabled: rgba($black, .25);

// Both Button and Type Colour
$active: $blue;
$accent: $primary;
$warn: #EDD161;
$alert: $red;
$activeHover: #3898EC;
//$red: red; // Keep for error states that need updating

// Secondary Colour Palette
$greyLight: #EBEBEB;
$greyMedium: #D7D7D7;
$grey: #828282;
$greyDark: #4B5553;
$greenLight: #A5FDE9;
$beige: #F7F3D6;
$blueLight: #C6E8FF;
$greyBlueBackground: #edf3f7;
$blueBackground: #E7F3FF;
$cardColor: #bd4fc6; //purple?
$whiteMedium: rgba(255, 255, 255, 0.54);
$redCancelled: #EF3D6D;

// Inverted Color Palette (white on black)
$textInverted: $white;
$textSecondaryInverted: #BCBDBB;
$textDisabledInverted: #8F9391;

//
$fillGrey2: #D9DDDF;

// Avatar Colors
$avatarColor0: #8FC0A1;
$avatarColor1: #B6D7AC;
$avatarColor2: #81D0BA;
$avatarColor3: #DDC9AC;
$avatarColor4: #BCA8AF;
$avatarColor5: #86B4C6;
$avatarColor6: #7ECCCA;
$avatarColor7: #92D48E;
$avatarColor8: #D6C991;
$avatarColor9: #C4E39B;
$avatarColor10: #D69B8D;
$avatarColor11: #73A88D;

// Country Colors
$USD: #36C97D;
$CAD: #F97A7A;
$EUR: #4E72BA;
$GBP: #E76170;
$AUS: #645C97;
$XXX: #24BCE5;

// Exports must match CSSTypes.UiColors interface
:export {
  black: $black;
  white: $white;
  green: $green;
  blue: $blue;
  red: $red;

  primary: $primary;
  secondary: $secondary;
  secondaryLabel: $secondaryLabel;

  text: $text;
  textSecondary: $textSecondary;
  textDisabled: $textDisabled;

  active: $active;
  accent: $accent;
  warn: $warn;
  alert: $alert;
  activeHover: $activeHover;

  greyLight: $greyLight;
  greyMedium: $greyMedium;
  grey: $grey;
  greyDark: $greyDark;
  greenLight: $greenLight;
  beige: $beige;
  blueLight: $blueLight;
  greyBlueBackground: $greyBlueBackground;
  blueBackground: $blueBackground;
  cardColor: $cardColor;
  whiteMedium: $whiteMedium;
  redCancelled: $redCancelled;

  textInverted: $textInverted;
  textSecondaryInverted: $textSecondaryInverted;
  textDisabledInverted: $textDisabledInverted;

  fillGrey2: $fillGrey2;

  avatarColor0: $avatarColor0;
  avatarColor1: $avatarColor1;
  avatarColor2: $avatarColor2;
  avatarColor3: $avatarColor3;
  avatarColor4: $avatarColor4;
  avatarColor5: $avatarColor5;
  avatarColor6: $avatarColor6;
  avatarColor7: $avatarColor7;
  avatarColor8: $avatarColor8;
  avatarColor9: $avatarColor9;
  avatarColor10: $avatarColor10;
  avatarColor11: $avatarColor11;

  USD: $USD;
  CAD: $CAD;
  EUR: $EUR;
  GBP: $GBP;
  AUS: $AUS;
  XXX: $XXX;
}
