@import "icons";

.drawer_main {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.drawer_header {
  height: 64px;
  border-bottom: 2px solid #E9F1F3;
}

.drawer_footer {
  padding: 24px 16px;
  border-top: 1px solid #CCC;
}

.drawer_nav_btn {
  display: inline-block;
  height: 64px;
  padding: 20px 8px 0 24px;
  cursor: pointer;
}

.drawer_btn_right {
  float: right;
  margin-right: 8px;
  padding-left: 16px;
}

.drawer_btn_left {
  float: left;
}

.drawer_title {
  display: inline-block;
  margin-left: 8px;
  margin-top: 16px;
}

.drawer_items {
  list-style-type: none;
  overflow: hidden;
  overflow-y: scroll;
  padding: 0;
  margin: 0;
  flex: 1;

  .drawer_item {
    min-height: 72px;
    display: flex;
    padding-left: 20px;

    &:hover {
      background-color: #E7F3FF;
      cursor: pointer;
    }

    &::before {
      width: 72px;
      align-self: center;
      text-align: center;
    }

    &.no_hover {
      &:hover {
        background-color: transparent;
        cursor: default;
      }
    }
  }

  .drawer_item_icon {
    width: 52px;
    height: 52px;
    //margin: 20px;
    margin: 0;
  }

  &.promotions {

    .drawer_item_icon {
      @extend .promotion_icon
    }
  }

  &.cards {
    .drawer_item::before {
      content: "Cards";
    }
  }

  .drawer_item_details {
    align-self: center;
    width: 245px;
    padding: 8px 16px;

    p {
      line-height: 1;
      margin-bottom: 4px;
    }
  }

  .drawer_add_item_btn {
    
  }

  + hr {
    margin-top: 0;
  }

}