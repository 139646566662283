.lr-app-main {
  height: 100%;
}

// AUTH
.lr-layout-auth-header {
  text-align: center;
  margin: 0 0 64px;

  h2 {
    margin: 72px 0 16px;
  }
}

// MAIN
.lr-layout-main-container {
  padding: 0 48px;
}

.lr-layout-main-header {
  padding: 24px 0;
  line-height: unset;
  height: auto;
}

.lr-layout-main-content {
  padding: 24px 0;
  background: #fff;
  min-height: unset;
}

.lr-layout-account-page {
  margin: 48px 0;
}

.lr-layout-header {
  margin-bottom: 24px;

  .lr-back-button {
    margin-right: 0.5rem;
  }

  .lr-layout-header-btn {
    + .lr-layout-header-btn {
      margin-left: 8px;
    }
  }

  ul.details {
    list-style: none;
    padding: 0;
    color: @text-color-secondary;

    li {
      margin: 0 0 8px 0;
    }
  }
}

.lr-layout-padded-spin {
  padding: 24px;
  text-align: center;
}

.lr-layout-content-section {
  margin: 24px 0;

  .lr-layout-content-section-header {
    margin-bottom: 16px;

    & h3 {
      margin-bottom: 0;
    }
  }
}

.lr-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1000;
}

.lr-detailValues-marginTop {
  margin-top: 24px;
}

.textCenter {
  text-align: center;
}

.alignRight {
  margin-left: auto;
}

.width100Perc {
  width: 100%;
}

.detailsNotFoundAlert {
  margin-bottom: 24px;
}

.lr-drawer-footer {
  margin-left: -24px;
  margin-right: -24px;
  margin-bottom: 0;
  width: 100%;
  padding: 16px;
  border-top: 1px solid #e8e8e8;
  position: absolute;
  bottom: 0;
  background: #fff;
  z-index: 10;

  .lr-drawer-footer-submit-button {
    margin-left: 8px;
  }
}

.lr-create-form-padding {
  padding-bottom: 30px;
}

.lr-list-header {
  margin-bottom: 24px;
  text-align: right;
}

.lr-top-margin {
  margin-top: 20px;
}

.lr-text-input {
  margin-left: 20px;
  width: 150px;
}

.lr-justify-right {
  display: flex;
  justify-content: flex-end;
}

.lr-justify-left {
  display: flex;
  justify-content: flex-start;
}

.lr-account-menu-item {
  display: flex;
  align-items: center;
}

.lr-toggle-switch {
  margin-left: 0;
  margin-right: 8px;
  margin-bottom: 16px;
  width: 45px;
}

.lr-inline-block {
  display: inline-block;
}