$xSmallSize: 480px;
$smallSize: 840px;
$mediumSize: 1280px;


@media only screen and (min-width: $smallSize + 1) {
  .mediumWidth100 { width: 100% !important; }
  .mediumWidth75  { width: 75% !important; }
  .mediumWidth50  { width: 50% !important; }
  .mediumWidth25  { width: 25% !important; }
  .mediumWidth33  { width: 33.3333% !important; }
  .mediumWidth66  { width: 66.6667% !important; }
}

@media only screen and (max-width: $smallSize) {
  .smallWidth100 { width: 100% !important; }
  .smallWidth75  { width: 75% !important; }
  .smallWidth50  { width: 50% !important; }
  .smallWidth25  { width: 25% !important; }
  .smallWidth33  { width: 33.3333% !important; }
  .smallWidth66  { width: 66.6667% !important; }
}

@media only screen and (max-width: $xSmallSize) {
  .xSmallWidth100 { width: 100% !important; }
  .xSmallWidth75  { width: 75% !important; }
  .xSmallWidth50  { width: 50% !important; }
  .xSmallWidth25  { width: 25% !important; }
  .xSmallWidth33  { width: 33.3333% !important; }
  .xSmallWidth66  { width: 66.6667% !important; }
}

//largeSize, mediumSize, smallSize, xSmallSize
@mixin breakpoint($point) {
  @if $point == largeSize {
    @media only screen and (min-width: $mediumSize) {
      @content;
    }
  } @else if $point == mediumSize {
    @media only screen and (max-width: $mediumSize) {
      @content;
    }
  } @else if $point == smallSize {
    @media only screen and (max-width: $smallSize) {
      @content;
    }
  } @else if $point == xSmallSize {
    @media only screen and (max-width: $xSmallSize) {
      @content;
    }
  }
}