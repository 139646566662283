.lr-title {
  color: rgba(0, 0, 0, 0.6);
  margin-bottom: 0.25em;
}

.lr-base-text-color {
  color: @text-color;
}

.lr-text-sm {
  font-size: 14px;
  color: @text-color-secondary;
}

.lr-text-danger {
  color: @redCanceled;

  &:hover {
    color: darken(@redCanceled, 70%);
  }
}

.lr-caption {
  &:extend(caption);
  display: inline-block;
  margin-bottom: 0;
}

.lr-title-postfix {
  margin: 0 0.5em;
  font-size: @font-size-base;
  color: @text-color-secondary;
}

.lr-title-postfix-sm {
  &:extend(.lr-text-sm);
  margin: 0 0.5em;
}

.lr-text-secondary {
  color: @text-color-secondary;
}

.lr-text-disabled {
  color: @disabled-color;
}

.lr-link {
  &:extend(a);
}

.lr-link-normal {
  &:extend(a);

  font-size: initial;
}

.lr-header-color {
  color: rgba(0, 0, 0, 0.6);
}

.lr-text-align-center {
  text-align: center;
}

// Testing alternate layout in SimpleStat.tsx
// NOT IN PRODUCTION
.lr-details-labels {
  &:extend(.lr-base-text-color);
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: 'calibre-medium', Helvetica, Arial, sans-serif;
}