.lr-vertical-radio {
  width: 100%;

  & > label {
    display: block;
    height: 30px;
    //line-height: 30px;
  }

  & > p {
    line-height: 22px;
  }
}

.lr-inline-edit-item-container {
  & ant-input-number-input {
    vertical-align: unset;
  }

  & textarea.ant-input {
    vertical-align: unset;
  }
}

.lr-inline-edit-item-value {
  line-height: 1.5;
  margin-bottom: 14px;
  padding: 4px 11px;
  background-color: @lr-theme-color-2;
  border-radius: @border-radius-base;
  border: 1px solid transparent;

  &.number {
    margin-bottom: 5px;
  }

  &:hover {
    cursor: pointer;
    border: 1px solid @primary-2;
  }

  transition: all 0.3s;
}

// PROGRAM RANGE INPUTS
.ant-input-group-wrapper.lr-inputRangeBorderLeft,
.ant-input.lr-inputRangeBorderRight,
.ant-input.lr-inputRangeBorderLeftRight {
  min-width: 100% !important;
  text-align: center !important;
}

.ant-input-group-wrapper.lr-inputRangeBorderLeft {
  .ant-input {
    border-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.ant-input.lr-inputRangeBorderRight {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 0;
}

.ant-input.lr-inputRangeBorderLeftRight {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 0;
  border-top: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
}

.lr-form-paragraph {
  margin-top: 16px;
  color: @lr-paragraph-color;
}

// ISSUANCE
.lr-create-form {
  & .ant-collapse {
    margin-bottom: 16px;
  }

  & .ant-collapse-header {
    & h3 {
      margin-bottom: 4px;
    }
  }

  & .ant-collapse-content {
    margin-top: 8px;
  }

  & .lr-code-preview-item {
    margin-bottom: 8px;

    & .ant-form-item-label {
      width: 100%;
      text-align: center;
    }

    & .codeDisplay.fullCode {
      margin: 0 auto;
    }

    & .ant-form-extra {
      text-align: center;
    }
  }
}

.lr-checkbox-group {
  & .ant-form-item {
    margin-bottom: 4px;
  }
}

.lr-fieldset {
  padding: 20px 8px 24px 20px;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  margin: 0 0 16px -8px;
  width: 100%;
  min-width: 360px;

  .ant-form-item {
    margin-bottom: 16px;

    input[type=text] {
      max-width: 94%;
    }

    .ant-input-group,
    .ant-input-group-wrapper {
      input[type=text] {
        max-width: 100%;
      }
    }

    &:last-of-type {
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }

  .setValue {
    margin-top: -16px;
  }
}

.lr-inset-sub-item {
  padding-left: 20px;
  border-left: 2px solid rgba(221, 221, 221, 0.5);
}

.lr-global-search-select {
  min-width: 110px;
}