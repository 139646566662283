@import "../../../styles/legacy/colors";

.contentContainer {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
}

.plansContainer {
  flex: 3 1 300px;
  margin: 0 24px 0 0;
}

.tiers {
  .tier {
    display: flex;
    flex-flow: row;
    align-items: flex-start;
    padding: 24px 0;

    .greyedOutText {
      p {
        color: $textDisabled;
      }
    }
  }

  .selectedTier {
    @extend .tier;

    .greyedOutText {
      p {
        color: $text;
      }
    }
  }

  .radioContainer {

  }

  .descriptionContainer {
    p {
      margin-bottom: 8px;
    }
  }
}

.creditCardContainer {
  width: 360px;
  border: 2px solid rgba(0, 0, 0, 0.08);
  padding: 8px;
  border-radius: 4px;
}