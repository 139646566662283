@import "breakpoints";

.visibility_hidden {
  visibility: hidden;
}

.display_none {
  display: none;
}

@media only screen and (max-width: $xSmallSize) {
  .hide_for_xsmall {
    display: none;
  }
}
@media only screen and (max-width: $smallSize) {
  .hide_for_small {
    display: none;
  }
}