
.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 0 56px 0;
}

.paper {
  align-self: stretch;
}

.unPaddedPaper {
  align-self: stretch;
}

.flexCenteredContainer {
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.flexCenteredRowContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.flexStartRowContainer {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.flexStartColumnContainer {
  flex-direction: column;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap
}

.submitProgramButtonContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 16px;
}

.descriptionSize {
  flex: 1;
  align-self: flex-start;
  padding-right: 20%;
  margin-bottom: 16px;
}

