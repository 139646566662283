.flex {
  display: flex;
}

.flexRowReverse {
  @extend .flex;
  flex-direction: row-reverse;
}

.flexWrap {
  @extend .flex;
  flex-wrap: wrap;
}

.flexColumn {
  flex: 1;
  min-width: 0;
}

.flexColumnReverse {
  @extend .flex;
  flex-direction: column-reverse;
}

.flexColumnTwoThirds {
  flex: 2;
}

.flexColumnHalf {
  flex-basis: 50%;
}

.flexRowSpaced8 {
  display: flex;
  > * {
    margin: 0 8px 8px 0;
  }
}

.flexRowSpaced16_0 {
  display: flex;
  > * {
    margin-right: 16px;
  }
}

.flexRowSpaced16_8 {
  display: flex;
  > * {
    margin: 0 16px 8px 0;
  }
}

.flexRowSpaced16 {
  display: flex;
  > * {
    margin: 0 16px 16px 0;
  }
}

.flexCenteredVerticle {
  display: flex;
  align-items: center;
}

.flexCentered {
  display: flex;
  align-items: center;
  justify-content: center;
}

.alignTop {
  align-items: flex-start;
}

.alignItemsCenter {
  align-items: center;
}

.alignItemsStart {
  align-items: flex-start;
}

.alignItemsBaseline,
.alignBaseline {
  align-items: baseline;
}

.justifyContentSpaceBetween {
  justify-content: space-between;
}

.justifyContentSpaceAround {
  justify-content: space-around;
}

.alignRight {
  margin-left: auto;
}

.flexAlignRight {
  margin-left: auto;
}

.flexAlignSelfEnd {
  align-self: flex-end;
}