.lr-color-percent-85 {
  color: rgba(0, 0, 0, 0.85);
}

.lr-color-percent-60 {
  color: rgba(0, 0, 0, 0.6);
}

.lr-color-percent-45 {
  color: rgba(0, 0, 0, 0.45);
}

@redCanceled: #EF3D6D;