@import "colors";
@import "flex";

// The className to add to the content's root element under the title.
.bodyDialogClass {

  @extend .flex;
  width: 100%;

  form {
    width: 100%;
  }

  p {
    font-size: 18px;
    line-height: 24px;
  }

  .column {

    @extend .flexColumn;

    &.columnLeft {
      padding-right: 24px;
    }
  }

  .half {
    min-width: 50% !important;
  }

  // This is optionally used in cases like the <InfoDialog/> to wrap the timezones for ease of reading for the user
  &.preWrap {
    white-space: pre-wrap;
  }

  [name="countryCode"] {
    width: 100% !important;
  }
}

// The className to add to the title's root container element.
.titleClass {

  &::before {
    content: "";
    width: 0;
    height: 48px;
    display: inline-block;
    vertical-align: middle;
  }

  &.hasIcon {
    &::before {
      content: "";
      width: 48px;
      height: 48px;
      display: inline-block;
      vertical-align: middle;
      margin-right: 16px;
    }
  }

  &.hasIcon {
    position: relative;
    &::after {
      width: 48px;
      height: 48px;
      position: absolute;

      //left: 24px; // the amount of padding needed for the title area because they are absolutely positioned
      //top: 16px;  // the amount of padding needed for the title area because they are absolutely positioned

      left: 20px; // the amount of padding needed for the title area because they are absolutely positioned
      top: 12px;  // the amount of padding needed for the title area because they are absolutely positioned

      font-family: "Material Icons";
      font-weight: normal;
      font-style: normal;
      //font-size: 48px;
      font-size: 57px;

      line-height: 1;
      text-transform: none;
      letter-spacing: normal;
      word-wrap: normal;
      white-space: nowrap;
      direction: ltr;
    }

    // Where to get the `content` codes: https://material.io/icons/
    &.info::after {
      content: "\E88E";
      color: $blue;
    }

    &.lock::after {
      content: "\E897";
      color: $grey;
    }

    &.help::after {
      content: "\E887";
      color: $blue;
    }

    &.success::after {
      content: "\E86C";
      color: $green;
    }

    &.error::after {
      content: "\E000";
      color: $alert;
    }

    &.confirm::after {
      content: "\E000";
      color: $warn;
    }
  }
}

// The className to add to the content container.
.contentClass {
  > div {
    // DANGEROUS: this value isn't exposed as a style and requires an important
    border-radius: 5px !important;
  }
}

// The className to add to the actions container's root element.
.actionsContainerClass {

  button {
    // DANGEROUS: this value isn't exposed as a style and requires an important
    margin: 0 0 0 8px !important;
  }
}

// Only used when the .actionsContainerClass is NOT being used because of forms
.actionsContainerClassOverride {
  text-align: right;
  margin-top: 30px;

  button {
    // DANGEROUS: this value isn't exposed as a style and requires an important
    margin: 0 -8px 2px 8px !important;
  }
}
