@import "breakpoints";

.tabsContainer {
  max-width: 480px;

  //@media only screen and (max-width: $smallSize) {
  //
  //}
  @media only screen and (max-width: $xSmallSize) {
    .tab {
      font-size: 11px !important;
    }
  }
}
