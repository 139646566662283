/**
 * Tooltip Styles
 */

/* Base styles for the element that has a tooltip */
[data-tooltip],
.tooltip {
  position: relative;
  cursor: pointer;
}

/* Base styles for the entire tooltip */
[data-tooltip]:before,
[data-tooltip]:after,
.tooltip:before,
.tooltip:after {
  position: absolute;
  visibility: hidden;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transition:
          opacity 0.2s ease-in-out,
          visibility 0.2s ease-in-out,
          -webkit-transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
  -moz-transition:
          opacity 0.2s ease-in-out,
          visibility 0.2s ease-in-out,
          -moz-transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
  transition:
          opacity 0.2s ease-in-out,
          visibility 0.2s ease-in-out,
          transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform:    translate3d(0, 0, 0);
  transform:         translate3d(0, 0, 0);
  pointer-events: none;
}

/* Show the entire tooltip on hover and focus */
[data-tooltip]:hover:before,
[data-tooltip]:hover:after,
.tooltip:hover:before,
.tooltip:hover:after {
  visibility: visible;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}

/* Base styles for the tooltip's directional arrow */
.tooltip:before,
[data-tooltip]:before {
  z-index: 1001;
  border: 6px solid transparent;
  background: transparent;
  content: "";
}

/* Base styles for the tooltip's content area */
.tooltip:after,
[data-tooltip]:after {
  z-index: 1000;
  padding: 10px;
  width: 130px;
  background-color: #000;
  background-color: hsla(0, 0%, 20%, 0.9);
  color: #fff;
  text-decoration: none;
  content: attr(data-tooltip);
  font-size: 14px;
  line-height: 1.2;
  border-radius: 4px;
}

/* Directions */

/* Top (default) */
[data-tooltip]:before,
[data-tooltip]:after,
.tooltip:before,
.tooltip:after,
.tooltipTop:before,
.tooltipTop:after {
  bottom: 100%;
  left: 50%;
}

[data-tooltip]:before,
.tooltip:before,
.tooltipTop:before {
  margin-left: -6px;
  margin-bottom: -12px;
  border-top-color: #000;
  border-top-color: hsla(0, 0%, 20%, 0.9);
}

/* Horizontally align top/bottom tooltips */
[data-tooltip]:after,
.tooltip:after,
.tooltipTop:after {
  margin-left: -80px;
}

[data-tooltip]:hover:before,
[data-tooltip]:hover:after,
.tooltip:hover:before,
.tooltip:hover:after,
.tooltipTop:hover:before,
.tooltipTop:hover:after {
  -webkit-transform: translateY(-12px);
  -moz-transform:    translateY(-12px);
  transform:         translateY(-12px);
}

/* Left */
.tooltipLeft:before,
.tooltipLeft:after {
  right: 100%;
  bottom: 50%;
  left: auto;
}

.tooltipLeft:before {
  margin-left: 0;
  margin-right: -12px;
  margin-bottom: 0;
  border-top-color: transparent;
  border-left-color: #000;
  border-left-color: hsla(0, 0%, 20%, 0.9);
}

.tooltipLeft:hover:before,
.tooltipLeft:hover:after {
  -webkit-transform: translateX(-12px);
  -moz-transform:    translateX(-12px);
  transform:         translateX(-12px);
}

/* Bottom */
.tooltipBottom:before,
.tooltipBottom:after {
  top: 100%;
  bottom: auto;
  left: 50%;
}

.tooltipBottom:before {
  margin-top: -12px;
  margin-bottom: 0;
  border-top-color: transparent;
  border-bottom-color: #000;
  border-bottom-color: hsla(0, 0%, 20%, 0.9);
}

.tooltipBottom:hover:before,
.tooltipBottom:hover:after {
  -webkit-transform: translateY(12px);
  -moz-transform:    translateY(12px);
  transform:         translateY(12px);
}

/* Right */
.tooltipRight:before,
.tooltipRight:after {
  bottom: 50%;
  left: 100%;
}

.tooltipRight:before {
  margin-bottom: 0;
  margin-left: -12px;
  border-top-color: transparent;
  border-right-color: #000;
  border-right-color: hsla(0, 0%, 20%, 0.9);
}

.tooltipRight:hover:before,
.tooltipRight:hover:after {
  -webkit-transform: translateX(12px);
  -moz-transform:    translateX(12px);
  transform:         translateX(12px);
}

/* Move directional arrows down a bit for left/right tooltips */
.tooltipLeft:before,
.tooltipRight:before {
  top: 3px;
}

.toolTipFirst.tooltipLeft:before,
.toolTipFirst.tooltipRight:before {
  top: 12px;
}

.toolTipLast.tooltipLeft:before,
.toolTipLast.tooltipRight:before {
  top: 12px;
}

/* Vertically center tooltip content for left/right tooltips */
.tooltipLeft:after,
.tooltipRight:after {
  margin-left: 0;
  margin-bottom: -20px;
}