//Block font loading
.loadingText {
  font-family: 'BLOKKNeue-Regular', 'Arial', sans-serif;

  color: $text;
  animation: colorShift 2s linear infinite;
  @keyframes :global(colorShift) {
    0% {
      color: $text;
    }
    50% {
      color: $greyMedium;
    }
    100% {
      color: $text;
    }
  }
}

//Loading
.Spinner {
  width: 60px;
  height: 60px;
  position: relative;
  margin: 0 auto 20px;
}

.SpinnerPageContainer{
  margin: 100px auto;
}

.Cube1, .Cube2 {
  background-color: #333;
  width: 15px;
  height: 15px;
  position: absolute;
  top: 0;
  left: 0;
  animation: cubemove 1.8s ease-in-out infinite;
}

.Cube2 {
  animation-delay: -0.9s;
}

@keyframes :global(cubemove) {
  25% {
    transform: translateX(42px) rotate(-90deg) scale(0.5);
  } 50% {
    transform: translateX(42px) translateY(42px) rotate(-179deg);
  } 50.1% {
    transform: translateX(42px) translateY(42px) rotate(-180deg);
  } 75% {
    transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5);
  } 100% {
    transform: rotate(-360deg);
  }
}