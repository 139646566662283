/*IMPORTANT : These styles are duplicated in giftbitapp for catalog signup/login.
If changing styles you may need to update them as well*/
@import "../../styles/legacy/colors";

h3 {
  margin-bottom: 16px;
}

.mainContent {
  width: 100%;
  display: flex;
  height: 100vh;
  overflow: hidden;
}

.contentSection {
  padding: 3rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  min-height: 400px;
}

.contentSectionLeft {
  background-color: #FFFFFF;
  flex: 2;
}

.contentSectionRight {
  text-align: center;
  background-color: $blue;
  flex: 1;
}

.forgotPassword {
  margin-left: 16px;
}

.linkText {
  margin-top: 0;
}
.successText,
.errorText {
  padding: 0 0 0 24px;
  margin: 0;
  display: flex;
  align-items: center;
  max-width: 300px;
}

.errorText {
  color: $alert;
}

ul.errorText {
  padding-left: 48px;
}

.errorList {
  padding-bottom: 8px;
}

.form {
  margin: 24px 0;
}

.formContainer {
  width: 100%;
  max-width: 360px;
  margin: 0 auto;
}

.bgImage {
  position: fixed;
  width: 50%;
  height: 100%;
  min-width: 960px;
  min-height: 1080px;
  z-index: 1;
}

.brandMessaging {
  max-width: 264px;
  margin: 0 auto;
  text-align: center;
}

.headerSection {
  p {
    color: #FFFFFF;
  }
}

// this needs to be changed.
.greyText {
  font-size: 14px;
  letter-spacing: 0;
  margin-bottom: 8px;
}

.marginBottom {
  margin-bottom: 24px;
}

@media (max-width: 600px) {
  .mainContent {
    flex-direction: column-reverse;
    height: auto;
  }
  .contentSection {
    width: 100%;
  }
  .hideOnMobile {
    display: none;
  }
  .contentSectionRight {
    min-height: 240px;
  }
}
@media (max-height: 700px) {
  .contentSectionRight {
    min-height: 0;
  }
}

