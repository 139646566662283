.container {
  padding: 16px 0;
}

.keyContainer {
  padding: 16px 0;
}

.memberRow {
  display: flex;
  flex-flow: row;
  align-items: center;
  padding: 4px 0;

  * {
    flex: 1;
  }

  .lastRowItem {
    align-items: flex-end;
    display: flex;
    flex-direction: row;
    flex: unset;
    margin-left: auto;
  }
}

.inviteTeamMemberContainer {
  margin: 16px 0;
}

.inviteLabel {
  color: #4b5553;
}