:global {
  @import "mixins";

  @import "visibility";
  @import "ui_dimensions";
  @import "dialog";
  @import "drawer";
  @import "colors";
  @import "backgrounds";
  @import "tooltips";
  @import "breakpoints";
  @import "loader";
  @import "flex";
  @import "images";
  @import "tabs";
  @import "typography";
  @import "animation";
  @import "header";
  @import "icons";
  ////////////////////////////////////////
  // Lastly, global overrides where needed
  @import "overrides";

  *, :after, :before {
    box-sizing: border-box;
  }

  // Allow the footer to stick to the bottom
  html, body {
    height: 100%;
    margin: 0;
    padding: 0;
    line-height: 1.3;
  }

  .bigInput {
    font-size: 32px;
  }

  //body {
  //  font-family: 'calibre-regular', 'Arial', sans-serif;
  //  font-size: 16px;
  //
  //  /* Support for all WebKit browsers. */
  //  -webkit-font-smoothing: antialiased;
  //  /* Support for Safari and Chrome. */
  //  text-rendering: optimizeLegibility;
  //
  //  /* Support for Firefox. */
  //  -moz-osx-font-smoothing: grayscale;
  //
  //  /* Support for IE. */
  //  font-feature-settings: 'liga';
  //}

  html [type="button"] {
    -webkit-appearance: inherit;
  }

  a {
    //font-size: 14px;
    text-decoration: none;
    cursor: pointer;
  }

  .loadingText {
    font-family: 'BLOKKNeue-Regular', 'Arial', sans-serif;
    color: $secondary;
  }

  .IeMinHeightFix {
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
  }

  .Aligner {
    display: flex;
    align-items: center;
    min-height: 24em;
    justify-content: center;
  }

  .AlignerItem {
    flex: 1;
  }

  .AlignerItemFixed {
    -webkit-box-flex: 0;
    flex: none;
    max-width: 50%;
  }

  .AppHeader, .AppFooter {
    flex-shrink: 0;
  }
  .Main {
    position: relative;
    transition: all 300ms ease-in;
    @include fullHeight;
    //background-color: #F0F5F7;
  }

  .Content {
    padding: 1rem 0;
  }

  .AppContent {
    -webkit-box-flex: 1;
    -webkit-flex: 1 0 auto;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    padding: 0;
    width: 100%;
  }

  .Container {
    max-width: 63em;
    margin: 0 auto;
    padding: 60px 1.5em;
  }

  .ContainerWide {
    padding: 0 11px;
  }

  .BaseContent {

  }

  .DetailLayout {
    .DetailHeader {
      width: 100%;
      background-color: $greyBlueBackground;
      .DetailHeaderContent {
        @extend .Content;
        background-color: unset;
        padding: 2rem 1rem;
      }
    }
    .DetailContent {
      @extend .Content;
      padding: 1rem 1rem 2rem 1rem;
    }
  }

  .verticalRadio {
    display: block;
    height: 30px;
    line-height: 30px;
  }

  ////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////
  /// TODO: Move these to where they are used, CSS Module styles
  //////////
  //////////
  .bigText {
    font-size: 5em;
  }

  .smallText {
    font-size: 14px;
  }

  .centeredText {
    text-align: center;
  }

  .vertCenterContent {
    display: flex;
    align-items: center;
  }

  .textGrey {
    color: $greyDark;
  }

  .borderBottom {
    padding-bottom: 3em !important;
    border-bottom: 3px solid #CCC;
  }

  .borderFull {
    border: 1px solid $greyMedium;
  }

  .verticalColumnStats {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .statLabel {
    //color: $greyDark;
    font-size: 16px;
    letter-spacing: 0.4px;
    display: block;
  }

  .statLabelSmall {
    //composes: statLabel;
    font-size: 16px;
    letter-spacing: 0.4px;
    display: block;
  }

  .tableHeadSort {
    font-size: 14px;
    font-family: 'calibre-regular', 'Arial', sans-serif;
    line-height: 2.8;
  }

  .statusOffset {
    display: inline-block;
    margin-top: 9px;
  }

  .pushDown {
    margin-bottom: 20px;
    width: 100%;
  }

  .circle {
    border-radius: 50%;
  }

  .fullHeight {
    @include fullHeight;
  }

  ul.naked-list {
    padding: 0;
    list-style-type: none;
  }
}

.newOrderButton {
  button {
    width: 60px !important; // important used to override inline styles ONLY!
    height: 60px !important; // important used to override inline styles ONLY!
  }
}

.tableResultsCount {
  display: inline-block;
  margin-right: 8px;
}

