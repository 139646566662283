// !important should generally be avoided, however this would be
// acting as an override class, specifically to override flexr

// Margin Spacing
.margin0 {
  margin: 0 !important
}

.margin8 {
  margin: 8px !important
}

.margin16 {
  margin: 16px !important
}

.margin24 {
  margin: 24px !important
}

.margin32 {
  margin: 32px !important
}

.margin40 {
  margin: 40px !important
}

.margin48 {
  margin: 48px !important
}

.margin56 {
  margin: 56px !important
}

.margin64 {
  margin: 64px !important
}

.margin72 {
  margin: 72px !important
}

.margin80 {
  margin: 80px !important
}

.marginTop0 {
  margin-top: 0 !important
}

.marginTop4 {
  margin-top: 4px !important
}

.marginTop8 {
  margin-top: 8px !important
}

.marginTop16 {
  margin-top: 16px !important
}

.marginTop24 {
  margin-top: 24px !important
}

.marginTop32 {
  margin-top: 32px !important
}

.marginTop40 {
  margin-top: 40px !important
}

.marginTop48 {
  margin-top: 48px !important
}

.marginTop56 {
  margin-top: 56px !important
}

.marginTop64 {
  margin-top: 64px !important
}

.marginTop72 {
  margin-top: 72px !important
}

.marginTop80 {
  margin-top: 80px !important
}


.marginRight0 {
  margin-right: 0 !important
}

.marginRight4 {
  margin-right: 4px !important
}

.marginRight8 {
  margin-right: 8px !important
}

.marginRight16 {
  margin-right: 16px !important
}

.marginRight24 {
  margin-right: 24px !important
}

.marginRight32 {
  margin-right: 32px !important
}

.marginRight40 {
  margin-right: 40px !important
}

.marginRight48 {
  margin-right: 48px !important
}

.marginRight152 {
  margin-right: 152px !important
}

.marginBottom0 {
  margin-bottom: 0 !important
}

.marginBottom6 {
  margin-bottom: 6px !important
}

.marginBottom8 {
  margin-bottom: 8px !important
}

.marginBottom16 {
  margin-bottom: 16px !important
}

.marginBottom24 {
  margin-bottom: 24px !important
}

.marginBottom32 {
  margin-bottom: 32px !important
}

.marginBottom40 {
  margin-bottom: 40px !important
}

.marginBottom48 {
  margin-bottom: 48px !important
}

.marginBottom56 {
  margin-bottom: 56px !important
}

.marginBottom64 {
  margin-bottom: 64px !important
}

.marginBottom72 {
  margin-bottom: 72px !important
}

.marginLeft-5 {
  margin-left: -5px !important
}

.marginLeft0 {
  margin-left: 0 !important
}

.marginLeft8 {
  margin-left: 8px !important
}

.marginLeft16 {
  margin-left: 16px !important
}

.marginLeft24 {
  margin-left: 24px !important
}

.marginLeft32 {
  margin-left: 32px !important
}

.marginLeft40 {
  margin-left: 40px !important
}

.marginLeft48 {
  margin-left: 48px !important
}

.marginLeft150 {
  margin-left: 150px !important
}

// Padding Spacing
.padding0 {
  padding: 0 !important
}

.padding8 {
  padding: 8px !important
}

.padding16 {
  padding: 16px !important
}

.padding24 {
  padding: 24px !important
}

.padding32 {
  padding: 32px !important
}

.padding40 {
  padding: 40px !important
}

.padding48 {
  padding: 48px !important
}

.padding56 {
  padding: 56px !important
}

.padding64 {
  padding: 64px !important
}

.paddingTop0 {
  padding-top: 0 !important
}

.paddingTop8 {
  padding-top: 8px !important
}

.paddingTop16 {
  padding-top: 16px !important
}

.paddingTop24 {
  padding-top: 24px !important
}

.paddingTop32 {
  padding-top: 32px !important
}

.paddingTop40 {
  padding-top: 40px !important
}

.paddingTop48 {
  padding-top: 48px !important
}

.paddingTop56 {
  padding-top: 56px !important
}

.paddingTop64 {
  padding-top: 64px !important
}

.paddingRight0 {
  padding-right: 0 !important
}

.paddingRight8 {
  padding-right: 8px !important
}

.paddingRight16 {
  padding-right: 16px !important
}

.paddingRight24 {
  padding-right: 24px !important
}

.paddingRight32 {
  padding-right: 32px !important
}

.paddingRight40 {
  padding-right: 40px !important
}

.paddingRight48 {
  padding-right: 48px !important
}

.paddingRight56 {
  padding-right: 56px !important
}

.paddingRight64 {
  padding-right: 64px !important
}

.paddingBottom0 {
  padding-bottom: 0 !important
}

.paddingBottom8 {
  padding-bottom: 8px !important
}

.paddingBottom16 {
  padding-bottom: 16px !important
}

.paddingBottom24 {
  padding-bottom: 24px !important
}

.paddingBottom32 {
  padding-bottom: 32px !important
}

.paddingBottom40 {
  padding-bottom: 40px !important
}

.paddingBottom48 {
  padding-bottom: 48px !important
}

.paddingBottom56 {
  padding-bottom: 56px !important
}

.paddingBottom64 {
  padding-bottom: 64px !important
}

.paddingLeft0 {
  padding-left: 0 !important
}

.paddingLeft8 {
  padding-left: 8px !important
}

.paddingLeft16 {
  padding-left: 16px !important
}

.paddingLeft24 {
  padding-left: 24px !important
}

.paddingLeft32 {
  padding-left: 32px !important
}

.paddingLeft40 {
  padding-left: 40px !important
}

.paddingLeft48 {
  padding-left: 48px !important
}

.paddingLeft56 {
  padding-left: 56px !important
}

.paddingLeft64 {
  padding-left: 64px !important
}

.height0 {
  height: 0 !important
}

.height8 {
  height: 8px !important
}

.height16 {
  height: 16px !important
}

.height24 {
  height: 24px !important
}

.height32 {
  height: 32px !important
}

.height40 {
  height: 40px !important
}

.height48 {
  height: 48px !important
}

.height56 {
  height: 56px !important
}

.height64 {
  height: 64px !important
}

.height72 {
  height: 72px !important;
}

.height80 {
  height: 80px !important;
}

.height88 {
  height: 88px !important;
}

.height96 {
  height: 96px !important;
}

.width0 {
  width: 0 !important
}

.width8 {
  width: 8px !important
}

.width16 {
  width: 16px !important
}

.width24 {
  width: 24px !important
}

.width32 {
  width: 32px !important
}

.width40 {
  width: 40px !important
}

.width48 {
  width: 48px !important
}

.width56 {
  width: 56px !important
}

.width64 {
  width: 64px !important
}

.width72 {
  width: 72px !important
}

.width80 {
  width: 80px !important
}

.width88 {
  width: 88px !important
}

.width96 {
  width: 96px !important
}

.minHeight8 {
  min-height: 8px
}

.minHeight16 {
  min-height: 16px
}

.minHeight24 {
  min-height: 24px
}