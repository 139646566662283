@import "../../../styles/legacy/colors";

.container {
  padding: 24px 0 32px;
}

.keyContainer {
  padding: 0 0 16px;
}

.apiKeyRow {
  display: flex;
  flex-flow: row;
  align-items: center;
  padding: 4px 0;

  * {
    flex: 1;
  }

  .lastRowItem {
    flex: 0;
    margin-left: auto;
  }
}

.generateKeyContainer {
  margin: 16px 0;
}

.newKeyDialogContainer {
  width: 100%;
}

.infoContainer {
  display: flex;
  flex-flow: row;

  .icon {
    //
  }

  .text {
    margin: 0 0 0 24px;

    .firstParagraph {
      margin-top: 0;
    }
  }
}

.apiTokenContainer {
  background-color: rgba(0, 0, 0, 0.04);
  font-size: 12px;
  font-family: monospace;
  border-radius: 4px;
  padding: 16px;
  margin: 16px 0;
  word-wrap: break-word;
}

//#turnkeyTokensContainer {
//  width: 100%;
//  display: flex;
//  justify-content: space-between;
//}