@import "colors";

.bgcGreen {
  //composes: backgrounds;
  position: relative;
  background-color: $greenLight;
}

.bgcWhite {
  //composes: backgrounds;
  position: relative;
  background-color: $white;
}

.bgcGrey {
  //composes: backgrounds;
  position: relative;
  background-color: $greyMedium;
}

.bgcDarkGrey {
  //composes: backgrounds;
  position: relative;
  background-color: $greyDark;
  color: $greyLight;
}

.bgcPeach {
  //composes: backgrounds;
  position: relative;
  background-color: $beige;
  color: $greyDark;
}

.bgcBlue {
  //composes: backgrounds;
  position: relative;
  background-color: $blueLight;
  color: $greyDark;
}