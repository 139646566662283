.fadeIn1Delay {
  animation: fadein_linear 1s;
}

.fadeIn2Delay {
  animation: fadein_linear 2s;
}

.fadeIn1Delay75 {
  animation: fadein_delay_75 1s;
}

.fadeIn2 {
  animation: fadein_linear 0.2s;
}

.fadeIn25 {
  animation: fadein_linear 0.25s;
}

.fadeIn3 {
  animation: fadein_linear 0.3s;
}

.fadeIn4 {
  animation: fadein_linear 0.3s;
}

.fadeInBounce3 {
  animation: fadein_bounch 0.3s;
}

.highlightBackground {
  animation: background_highlight_accent1 1s;
}

@keyframes:global(fadein_linear) {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes:global(fadeout_display) {
  from {
    opacity: 1;
  }
  to {
    display: none;
    opacity: 0;
  }
}

@keyframes:global(fadein_bounce) {
  0% {
    opacity: 0;
    transform: scale(.1);
  }
  85% {
    opacity: 1;
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes:global(fadin_delay_50) {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes:global(fadin_delay_75) {
  0% {
    opacity: 0;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes:global(background_highlight_accent1) {
  0% {
    background-color: white;
  }
  50% {
    background-color: hotpink;
  }
  100% {
    background-color: white;
  }
}

.fadeOpacity0 {
  opacity: 0;
  transition: opacity 300ms;
}

.fadeOpacity1 {
  opacity: 1;
  transition: opacity 300ms linear;
}


@keyframes:global(scaleIt) {
  0% {
    transform: scale3d(1, 1, 1);
  }
  100% {
    transform: scale3d(1.3, 1.3, 1);
  }
}

.scaleIt {
  animation: scaleIt .5s ease-in .75s both;
}