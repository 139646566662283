@import "../../styles/legacy/ui_dimensions";
@import "../../styles/legacy/colors";
@import "../../styles/legacy/breakpoints";

.root {
  width: 100%;
}

//.tabsRoot {
//  //width: 80%;
//  //max-width: $maxContentWidth;
//  //margin: 0 auto;
//
//  @media only screen and (max-width: $mediumSize) {
//    width: 100%;
//  }
//}
