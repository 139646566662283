@import "../../../../styles/legacy/colors";

.form {
  margin: 0;
}

.flexRow {
  display: flex;

  .flexColumn {
    flex: 1;
    margin-right: 24px;

    + .flexColumn {
      margin-right: 0;
    }
  }
}

.ccInfoHeader,
.billingHeader {
  font-size: 24px;
  line-height: 32px;
}

.ccInfoHeader {
  margin: 0 0 24px;
}

.billingHeader {
  margin: 40px 0 24px;
}

.cardDetails {
  border-radius: 10px;
  border: 1px solid $greyMedium;
  padding: 24px 32px;
  background-color: $greyLight;
  max-width: 450px;
  margin: 0 auto;
}

.cardImage {
  height: 32px;
  margin: 30px 0 0 8px;
}

.errorRow {
  display: flex;
  align-items: center;
  margin: 8px 0;
  color: $alert;
}

.errorRow p {
  margin: 0;
  font-size: 14px;
}

.submitRow {
  margin-top: 8px;
}

cc-icon-container {
  width: 48px;
}

.cc-number-container {
  width: calc(100% - 48px);
}