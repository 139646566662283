.maintenance-layout-container {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
}

.maintenance-layout-inner-container {
  display: flex;
  flex-flow: column;
  align-items: center;
  margin: 0 auto;
}

.maintenance-layout-logo-container {
  margin: 0 0 64px 0;
}

.maintenance-layout-text-container {
  display: flex;
  flex-flow: column;
  align-items: center;
  text-align: center;
  padding: 0 16px;
}

.maintenance-layout-title {
  margin: 0 0 16px 0;
}

