@import "breakpoints";

.Header {
  min-height: calc(#{$globalHeightSpacing} * 14);
}

.pageTitle {
  line-height: 1;
  max-width: 530px;
  text-overflow: ellipsis;
  overflow: hidden;

  @media only screen and (max-width: $smallSize) {
    min-height: 24px;
  }
}